// src/components/InfiniteScroll/InfiniteScroll.js
import React, { useRef, useCallback } from 'react';

const InfiniteScroll = ({ children, loadMore, hasMore, className }) => {
  const observerRef = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [hasMore, loadMore]
  );

  return (
    <div className={className}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, { ref: index === React.Children.count(children) - 1 ? lastElementRef : null });
      })}
    </div>
  );
};

export default InfiniteScroll;
