export function openDB(dbName, storeName) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

export function saveToDB(dbName, storeName, data) {
  return new Promise((resolve, reject) => {
    openDB(dbName, storeName).then((db) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put(data);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getFromDB(dbName, storeName, key) {
  return new Promise((resolve, reject) => {
    openDB(dbName, storeName).then((db) => {
      const transaction = db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(key);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getAllFromDB(dbName, storeName) {
  return new Promise((resolve, reject) => {
    openDB(dbName, storeName).then((db) => {
      const transaction = db.transaction([storeName], 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.getAll();

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export function deleteFromDB(dbName, storeName, key) {
  return new Promise((resolve, reject) => {
    openDB(dbName, storeName).then((db) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.delete(key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    }).catch((error) => {
      reject(error);
    });
  });
}
