import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import styles from "./SettingsPage.module.css";

function SettingsPage() {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('selectedCountry') || 'en');

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    localStorage.setItem('selectedCountry', newCountry);
  };

  return (
    <div className={styles.settingsPage}>
      <Header showBackIcon={true} title={"Settings"} navigate={navigate} />
      <select value={selectedCountry} onChange={handleCountryChange}>
      <option value="en">English (en)</option>
        <option value="km">Khmer (km)</option>
        <option value="ne">Nepali (ne)</option>
        <option value="vi">Vietnamese (vi)</option>
      </select>
    </div>
  );
}

export default SettingsPage;
