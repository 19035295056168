import ko from '../data/voca_ko.json';
import en from '../data/voca_en.json';
import km from '../data/voca_km.json';
import ne from '../data/voca_ne.json';
import vi from '../data/voca_vi.json';

export const getVocaData = (level, type, selectedCountry) => {
  const vocaData = {
    ko: ko[level][type],
    en: en[level][type],
    km: km[level][type],
    ne: ne[level][type],
    vi: vi[level][type],
  };

  return {
    wordList: vocaData[selectedCountry],
    translation: vocaData[selectedCountry],
    defaultWordList: vocaData.ko,
  };
};
