import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import CategoryPage from "./pages/Category/CategoryPage";
import WordListPage from "./pages/WordList/WordListPage";
import FavoritesPage from "./pages/Favorites/Favorites";
import SettingsPage from "./pages/Settings/SettingsPage";
import "./App.css";
import ko from "./data/voca_ko.json";

function App() {
  const [vocaData, setVocaData] = useState({});

  useEffect(() => {
    setVocaData(ko);
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage vocaData={vocaData} />} />
          <Route
            path="/category/:level"
            element={<CategoryPage vocaData={ko} />}
          />
          <Route
            path="/category/:level/:type"
            element={<WordListPage vocaData={ko} />}
          />
          <Route path="/Favorites" element={<FavoritesPage vocaData={ko} />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
