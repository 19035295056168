import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back_arrow.svg';
import { ReactComponent as FavoriteIcon } from '../../assets/icons/Favorite.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo_small.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import styles from './Header.module.css';
import { getAllFromDB } from '../../utils/indexedDBUtils';

function Header({ showLogo, showBackIcon, showFavoriteIcon, showSettingsIcon, title }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // IndexedDB에서 즐겨찾기 상태 가져오기
    getAllFromDB('favoritesDB', 'favoritesStore').then((results) => {
      const hasFavorites = results.some((item) => item.isFavorite);
      setIsFavorite(hasFavorites);
    }).catch((error) => {
      console.error('Failed to get all from DB:', error);
    });
  }, []);

  return (
    <header className={styles.header}>
      {showBackIcon && (
        <div className={styles.backContainer}>
          <BackIcon className={styles.backIcon} onClick={() => navigate(-1)} />
          {title && <span className={styles.title}>{title}</span>}
        </div>
      )}
      <div className={styles.leftContainer}>
        {showSettingsIcon && (
          <Link to="/settings">
            <SettingsIcon alt="Settings Icon" className={styles.settingsIcon} />
          </Link>
        )}
        {showLogo && <LogoIcon alt="logo" className={styles.logoIcon} />}
      </div>
      <div className={styles.iconContainer}>
        {showFavoriteIcon && (
          <Link to="/favorites">
            <FavoriteIcon
              alt="Favorite Icon"
              className={`${styles.favoriteIcon} ${isFavorite ? styles.favoriteIconActive : ''}`}
            />
          </Link>
        )}
      </div>
    </header>
  );
}

export default Header;
