import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import styles from "./HomePage.module.css";
import { ReactComponent as RightArrowIcon } from "../../assets/icons/right_arrow.svg";
import { formatNumber } from "../../utils/string";

function HomePage({ vocaData }) {
  const navigate = useNavigate();
  const levels = Object.keys(vocaData);

  if (!localStorage.getItem('selectedCountry')) {
    localStorage.setItem('selectedCountry', 'en');
  }
  
  const getTotalWordCount = (level) => {
    const categories = Object.values(vocaData[level]);
    return categories.reduce((total, category) => total + category.length, 0);
  };

  return (
    <div className={styles.homepage}>
      <Header
        showSettingsIcon={true}
        showLogo={true}
        showBackIcon={false}
        showFavoriteIcon={true}
        navigate={navigate}
      />
      {levels.map((level, index) => (
        <Link to={`/category/${level}`} key={index} className={styles.link}>
          <div className={styles.categoryCard}>
            <div className={styles.cardContent}>
              <h2>{level}</h2>
              <p>Words: {formatNumber(getTotalWordCount(level))}</p>
            </div>
            <RightArrowIcon alt="right_arrow" />
          </div>
        </Link>
      ))}
    </div>
  );
}

export default HomePage;
