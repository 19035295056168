import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import FlippableCard from '../../components/FlippableCard/FlippableCard';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import styles from './Favorites.module.css';
import { getAllFromDB, deleteFromDB } from '../../utils/indexedDBUtils';
import { getVocaData } from '../../utils/vocaData';

function FavoritesPage() {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [flipped, setFlipped] = useState({});
  const [visibleCount, setVisibleCount] = useState(10);
  const [selectedCountry] = useState(localStorage.getItem('selectedCountry') || 'en');

  useEffect(() => {
    getAllFromDB('favoritesDB', 'favoritesStore').then((results) => {
      const favoriteWords = results.filter((item) => item.isFavorite);

      const updatedFavorites = favoriteWords.map((item) => {
        const { defaultWordList, translation } = getVocaData(item.level, item.type, selectedCountry);
        return {
          ...item,
          word: defaultWordList[item.index]?.word,         // 기본 언어
          pos: defaultWordList[item.index]?.pos,           // 기본 언어
          sentence: defaultWordList[item.index]?.sentence, // 기본 언어
          translationWord: translation[item.index]?.word,
          translationSentence: translation[item.index]?.sentence
        };
      });

      setFavorites(updatedFavorites);

      const initialFlipped = {};
      favoriteWords.forEach((_, index) => {
        initialFlipped[index] = false;
      });
      setFlipped(initialFlipped);
    }).catch((error) => {
      console.error('Failed to get all from DB:', error);
    });
  }, [selectedCountry]);

  const handleFlipClick = (index) => {
    setFlipped((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleFavoriteClick = (key) => {
    const newFavorites = favorites.map((item) => 
      item.id === key ? { ...item, isFavorite: false } : item
    ).filter(item => item.isFavorite);
    setFavorites(newFavorites);
    deleteFromDB('favoritesDB', 'favoritesStore', key);
  };

  const loadMoreFavorites = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 10, favorites.length));
  };

  return (
    <div className={styles.favoritesPage}>
      <Header showBackIcon={true} title="Favorites" navigate={navigate} />
      <InfiniteScroll className={styles.wordlist} loadMore={loadMoreFavorites} hasMore={visibleCount < favorites.length}>
        {favorites.length === 0 ? (
          <div className={styles.noFavorites}>찜 목록이 없습니다</div>
        ) : (
          favorites.slice(0, visibleCount).map((word, index) => (
            <FlippableCard
              key={index}
              index={index}
              isFlipped={flipped[index]}
              word={word.word}  // 기본 언어
              pos={word.pos}
              sentence={word.sentence} // 기본 언어
              translationWord={word.translationWord}
              translationSentence={word.translationSentence}
              isFavorite={word.isFavorite}
              onFlip={handleFlipClick}
              onFavoriteClick={() => handleFavoriteClick(word.id)}
            />
          ))
        )}
      </InfiniteScroll>
    </div>
  );
}

export default FavoritesPage;
