import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import styles from './CategoryPage.module.css';
import { formatNumber } from '../../utils/string';

function CategoryPage({ vocaData }) {
  const navigate = useNavigate();
  const { level } = useParams();
  const categories = Object.keys(vocaData[level]);

  return (
    <div className={styles.categoryPage}>
      <Header showBackIcon={true} title={level} navigate={navigate} />
      <div className={styles.categoryGrid}>
        {categories.map((category, index) => (
          <Link to={`/category/${level}/${category}`} key={index} className={styles.link}>
            <div className={styles.categoryItem}>
              <h3>{category}</h3>
              <div className={styles.numberContainer}>
                <div className={styles.number}>{index + 1}</div>
                <div className={styles.count}>0 / {formatNumber(vocaData[level][category].length)}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;
