import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import { ReactComponent as FavoriteIcon } from '../../assets/icons/Favorite.svg';
import { ReactComponent as SpeakerIcon } from '../../assets/icons/Speaker.svg';
import styles from './FlippableCard.module.css';

const FlippableCard = React.forwardRef((props, ref) => {
  const [, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);

      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const selectedVoiceIndex = isMobile ? 0 : 12;

      if (availableVoices.length > 0) {
        setSelectedVoice(availableVoices[selectedVoiceIndex] || availableVoices[0]);
      }
    };

    loadVoices();
    synth.onvoiceschanged = loadVoices;

    return () => {
      synth.onvoiceschanged = null;
    };
  }, []);

  const handleSpeak = (text) => {
    if (selectedVoice) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = selectedVoice;
      window.speechSynthesis.speak(utterance);
    }
  };

  const highlightWord = (text, wordToHighlight) => {
    if (!wordToHighlight) return text;
    const parts = text.split(new RegExp(`(${wordToHighlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === wordToHighlight.toLowerCase()
        ? <span key={index} className={styles.highlight}>{part}</span>
        : part
    );
  };

  return (
    <ReactCardFlip isFlipped={props.isFlipped} flipDirection="horizontal">
      <div className={`${styles.worditem} ${styles.worditemFront}`} onClick={() => props.onFlip(props.index)} ref={ref}>
        <span className={styles.wordpos}>Word {props.index + 1} | {props.pos}</span>
        <div className={styles.icons}>
          <SpeakerIcon className={styles.speakerIcon} onClick={(e) => { e.stopPropagation(); handleSpeak(props.word); }} />
          <FavoriteIcon className={`${styles.favoriteIcon} ${props.isFavorite ? styles.favoriteIconActive : ''}`} onClick={(e) => { e.stopPropagation(); props.onFavoriteClick(props.index); }} />
        </div>
        <h3>{props.word}</h3>
      </div>
      <div className={`${styles.worditem} ${styles.worditemBack}`} onClick={() => props.onFlip(props.index)}>
        <span className={styles.wordpos}>Word {props.index + 1} | {props.pos}</span>
        <div className={styles.icons}>
          <SpeakerIcon className={styles.speakerIcon} onClick={(e) => { e.stopPropagation(); handleSpeak(props.sentence); }} />
          <FavoriteIcon className={`${styles.favoriteIcon} ${props.isFavorite ? styles.favoriteIconActive : ''}`} onClick={(e) => { e.stopPropagation(); props.onFavoriteClick(props.index); }} />
        </div>
        <h3>{props.translationWord}</h3>
        <p>{highlightWord(props.sentence, props.word)}</p>
        <p>{highlightWord(props.translationSentence, props.word)}</p>
      </div>
    </ReactCardFlip>
  );
});

export default FlippableCard;
