import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from "../../components/Header/Header";
import FlippableCard from '../../components/FlippableCard/FlippableCard';
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import styles from './WordListPage.module.css';
import { getFromDB, saveToDB } from '../../utils/indexedDBUtils';
import { getVocaData } from '../../utils/vocaData';

function WordListPage() {
  const navigate = useNavigate();
  const { level, type } = useParams();
  const [selectedCountry] = useState(localStorage.getItem('selectedCountry') || 'km');

  const { wordList, translation, defaultWordList } = getVocaData(level, type, selectedCountry);

  const [flipped, setFlipped] = useState(Array(wordList.length).fill(false));
  const [visibleCount, setVisibleCount] = useState(10);
  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    wordList.forEach((word, index) => {
      const key = `${level}-${type}-${index}`;
      getFromDB('favoritesDB', 'favoritesStore', key).then((result) => {
        if (result) {
          setFavorites((prev) => ({ ...prev, [key]: result }));
        }
      }).catch((error) => {
        console.error('Failed to get from DB:', error);
      });
    });
  }, [level, type, wordList]);

  const handleClick = (index) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index];
    setFlipped(newFlipped);
  };

  const handleFavoriteClick = (index) => {
    const key = `${level}-${type}-${index}`;
    const isFavorite = !favorites[key]?.isFavorite;
    const favoriteData = { 
      id: key, 
      isFavorite, 
      word: defaultWordList[index]?.word,
      sentence: defaultWordList[index]?.sentence,
      pos: defaultWordList[index]?.pos,
      level,
      type,
      index,
      translationWord: translation[index]?.word,
      translationSentence: translation[index]?.sentence
    };
    setFavorites((prev) => ({ ...prev, [key]: favoriteData }));
    saveToDB('favoritesDB', 'favoritesStore', favoriteData);
  };

  const loadMoreWords = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 10, wordList.length));
  };

  return (
    <div className={styles.wordlistpage}>
      <Header 
        showBackIcon={true} 
        title={type} 
        navigate={navigate} 
        favoriteKey={`${level}-${type}`}
      />
      <InfiniteScroll className={styles.wordlist} loadMore={loadMoreWords} hasMore={visibleCount < wordList.length}>
        {wordList.slice(0, visibleCount).map((word, index) => (
          <FlippableCard
            key={index}
            index={index}
            isFlipped={flipped[index]}
            word={defaultWordList[index]?.word}         // 기본 언어
            pos={defaultWordList[index]?.pos}           // 기본 언어
            sentence={defaultWordList[index]?.sentence} // 기본 언어
            translationWord={translation[index]?.word}
            translationSentence={translation[index]?.sentence}
            isFavorite={favorites[`${level}-${type}-${index}`]?.isFavorite}
            onFlip={handleClick}
            onFavoriteClick={() => handleFavoriteClick(index)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default WordListPage;
