import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "../src/assets/fonts/font.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const preventRightClick = (e) => {
  e.preventDefault();
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

document.addEventListener("contextmenu", preventRightClick);
reportWebVitals();
